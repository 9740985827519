import Vue from 'vue'
import Vuex from 'vuex'
//import Axios from '../../plugins/axios'
import Axios from 'axios';

import bridge from "@vkontakte/vk-bridge"

Vue.use(Vuex)

export default ({
    state: {
        load: false,
        person: {
            name: 'Аноним',
            link: 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.flaticon.com%2Fru%2Ffree-icon%2Fno-photo_4054617&psig=AOvVaw0brArw1uVrUfJ7X8q7mFbv&ust=1717640926577000&source=images&cd=vfe&opi=89978449&ved=0CBAQjRxqFwoTCJCNlJy1w4YDFQAAAAAdAAAAABAE',
            id: '',
            phone: '',
            fname: '',
            lname: '',
        },
        listEvent: [],

    },
    getters: {
        getLoad(state) {
            return state.load;
        },
        getPerson(state) {
            return state.person;
        },
        getEvent(state) {
            return state.listEvent;
        },


    },


    mutations: {
        onLoad(state, payload) {
            state.load = payload;
        },

        UpdateEvent(state, payload) {
            state.listEvent = payload;
        },

        updatePhoto(state, payload) {
            state.person.name = payload.last_name + ' ' + payload.first_name;
            state.person.fname = payload.first_name;
            state.person.lname = payload.last_name;
            state.person.link = payload.photo_200;
            state.person.id = payload.id
            console.log(state.person);
        },

        updatePhoneUser(state, payload) {
            state.person.phone = payload;
            console.log(state.person)
        },

    },
    actions: {
        setPhone({ commit, state }) {
            commit("onLoad", true);
            return new Promise((resolve, reject) => {
                bridge.send('VKWebAppGetPhoneNumber')
                    .then((data) => {
                        if (data.phone_number) {
                            commit("onLoad", false);
                            console.log(data.phone_number);
                            commit("updatePhoneUser", data.phone_number);
                            let t = {
                                id: state.person.id,
                                ph: data.phone_number,
                                fnm: state.person.fname,
                                lnm: state.person.lname,
                            }
                            console.log(t);

                            Axios.post("api/v1/cvetomamdata/vk/set/info", t)
                                .then((res) => {
                                    console.log(res);
                                    commit("onLoad", false);
                                    commit("UpdateEvent", res.data.message);
                                    resolve(res.data.message.length);

                                })
                                .catch(() => {
                                    console.log("error");
                                    reject(0);
                                });

                        }
                    })
                    .catch((error) => {
                        // Ошибка
                        console.log(error);
                    });
            });
        },
        saveEvent({ commit, state }, payload) {
            commit("onLoad", true);
            let t = {
                idp: state.person.id,
                ide: payload.id,
                day: payload.dts,
                month: payload.dtm,
                desc: payload.name,
            }

            Axios.post("api/v1/cvetomamdata/vk/set/event", t)
                .then((res) => {
                    console.log('event');
                    console.log(res);
                    commit("onLoad", false);
                    commit("UpdateEvent", res.data.message);

                })
                .catch(() => {
                    console.log("error");

                });

        },

        delEvent({ commit, state }, payload) {
            commit("onLoad", true);
            let t = {
                idp: state.person.id,
                ide: payload
            }
            Axios.post("api/v1/cvetomamdata/vk/del/event", t)
                .then((res) => {
                    console.log('delevent');
                    console.log(res);
                    commit("onLoad", false);
                    commit("UpdateEvent", res.data.message);
                })
                .catch(() => {
                    console.log("error");
                });
        },

        setNotify({ commit }) {
            commit("onLoad", true);
            bridge.send('VKWebAppAllowNotifications')
                .then((data) => {
                    if (data.result) {

                        console.log(data);

                    } else {
                        commit("onLoad", false);
                        console.log('err');
                    }
                })
                .catch((error) => {
                    // Ошибка
                    commit("onLoad", false);
                    console.log(error);
                });

            bridge.send('VKWebAppGetUserInfo')
                .then((data) => {
                    if (data.id) {
                        // Данные пользователя получены
                        console.log(data.id);
                        commit("updatePhoto", data);

                        Axios.post("api/v1/cvetomamdata/vk/get/info", {
                            id: data.id
                        })
                            .then((res) => {
                                console.log('user');
                                console.log(res);
                                commit("onLoad", false);
                                if (res.data.message.length != 0) {
                                    commit("updatePhoneUser", res.data.message.pers[0].phone);
                                    commit("UpdateEvent", res.data.message.event);
                                }


                            })
                            .catch(() => {
                                console.log("error");
                            });
                    }
                })
                .catch((error) => {
                    // Ошибка
                    console.log(error);
                });

        },

    },
})