import Vue from 'vue'
import Axios from 'axios';
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import bridge from "@vkontakte/vk-bridge"

Vue.config.productionTip = false
bridge.send("VKWebAppInit");

new Vue({
  store,
  router,
  vuetify,
  created () {
    Axios.defaults.baseURL = 'https://humacl.x-or.ru/';
  },
  render: h => h(App)
}).$mount('#app')
