<template>
  <v-app >
    <v-app-bar style="margin: 5px" app color="#21a7ac" dark>
      <v-avatar style="margin-right: 10px;">
        <v-img :src="user.link" alt="ПЛ" />
      </v-avatar>
      {{ user.name }}
      <v-spacer></v-spacer>
      Цветочный клуб "Цветомама"
      <v-spacer></v-spacer>

      
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    
  }),
  computed: {
        user() {
            return this.$store.getters.getPerson;
        },
      },
  mounted() {
    this.$store.dispatch("setNotify");
  }
};
</script>
