<template>
    <v-container grid-list-xs>
        <v-row>
            <v-dialog v-model="dialog" width="500">

                <v-card>
                    <v-card-title style="background-color: #21a7ac;" class="text-h5 lighten-2">
                        Внимание!
                    </v-card-title>

                    <v-card-text>
                        Данные О пользователе не получены!

                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="#21a7ac" text @click="dialog = false">
                            Ясно
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-row>
        <v-row>
            <v-col>
                <v-card v-if="etap == 0">

                    <v-card-text style="text-align: center;">
                        <v-img src="../../assets/cveto.png" width="200px" style="margin: auto;"></v-img>
                       Привет! 👋Я - Цветомама и я помогу вам быть заботливее и внимательнее к близким. Я напомню вам о ваших памятных событиях, а также заранее предупрежу садовников, чтобы они срезали цветы под ваш заказ и сделали вам хорошую скидку! После регистрации в клубе - вы получите пожизненную скидку 20% на наши букеты, а также подарок!


                        После нажатия кнопки «Дальше» необходимо разрешить доступ приложения к номеру телефона, так как
                        он является уникальным идентификатором в нашей базе.
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="#21a7ac" class="mr-4" @click="NextEtap">
                            Дальше
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
                <v-card v-if="etap == 1">

                    <v-card-text>
                        Укажи свой номер телефона чтобы я смогла предоставить тебе скидку и подарок в нашем магазине.
                        <v-form ref="form">
                            <v-text-field label="Ваш телефон"></v-text-field>
                        </v-form>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="#21a7ac" class="mr-4" @click="NextEtap">
                            Дальше
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
                <v-card v-if="etap == 2">

                    <v-card-text>
                        Как только вы внесете 3 даты - мы подарим вам 1 розу и предоставим пожизненную скидку 20% на наши букеты! Скидки не распространяются на акционные товары
Внимание! Общие праздники (8 марта, День матери и тому подобное) вносить не нужно!



                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="#21a7ac" class="mr-4" @click="NextEtap">
                            Начать добавлять события!
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
                <!-- dsad -->
                <v-card v-if="etap == 5" :color="items.color" dark>
                    <div class="d-flex flex-no-wrap justify-space-between">
                        <div>
                            <v-card-title class="text-h5">События</v-card-title>

                            <v-card-subtitle v-if="listEvent.length==0">Как только вы внесете 3 даты - мы подарим вам 1 розу и предоставим пожизненную скидку 20% на наши букеты! Скидки не распространяются на акционные товары
Внимание! Общие праздники (8 марта, День матери и тому подобное) вносить не нужно!</v-card-subtitle>

                            <v-card-subtitle v-if="listEvent.length>=3"> Поздравляю! Вы добавили все события! Мы напомним о них вам за 10, за 4 и за 1 день и предложим подходящие букеты с вашей персональной скидкой.

Можете добавить или отредактировать события через кнопку «Список событий». Получить свою подарочную розу вы можете у нас лично в магазине, либо мы можем приложить ее к вашему заказу. А также у вас теперь пожизненная скидка в 20% на все наши букеты, кроме акционных. Для полчения подарка при заказе посто назовите нашему специалисту код ROZA4345</v-card-subtitle>

                         <v-card-subtitle v-if="listEvent.length==1"> Поздравляю! Вы добавили новое событие - мы напомним о нем вам за 10, за 4 и за 1 день и предложим подходящие букеты с вашей персональной скидкой. Для активации сервиса и получения подарка осталось добавить минимум 2 события!
</v-card-subtitle>
                         <v-card-subtitle v-if="listEvent.length==2"> Поздравляю! Вы добавили новое событие - мы напомним о нем вам за 10, за 4 и за 1 день и предложим подходящие букеты с вашей персональной скидкой. Для активации сервиса и получения подарка осталось добавить минимум 1 событие!
</v-card-subtitle>



                            <v-card-actions>

                                <v-btn class="ml-2 mt-5" outlined rounded small @click="etap = 3">
                                    Добавить новое событие
                                </v-btn>
                            </v-card-actions>
                        </div>
                        <!-- <v-avatar class="ma-3" size="125" tile>
                            <v-img :src="items.src"></v-img>
                        </v-avatar> -->
                    </div>
                </v-card>
                <v-card v-if="etap == 5 && listEvent.length>2" color="#385F73" dark style="margin-top: 5px;">
                    <div class="d-flex flex-no-wrap justify-space-between">
                        <div>
                            <v-card-title class="text-h5">Список событий</v-card-title>

                            <v-card-subtitle>Посмотреть ваши события</v-card-subtitle>

                            <v-card-actions>

                                <v-btn class="ml-2 mt-5" outlined rounded small @click="etap = 6">
                                    Список событий
                                </v-btn>
                            </v-card-actions>
                        </div>
                        <!-- <v-avatar class="ma-3" size="125" tile>
                            <v-img :src="items.src"></v-img>
                        </v-avatar> -->
                    </div>
                </v-card>
                <!-- <v-card v-if="etap == 5" color="#952175" dark style="margin-top: 5px;">
                    <div class="d-flex flex-no-wrap justify-space-between">
                        <div>
                            <v-card-title class="text-h5">Бонусы</v-card-title>

                            <v-card-subtitle>Посмотреть ваши бонусы</v-card-subtitle>

                            <v-card-actions>

                                <v-btn class="ml-2 mt-5" outlined rounded small>
                                    Посмотреть
                                </v-btn>
                            </v-card-actions>
                        </div>
                        <v-avatar class="ma-3" size="125" tile>
                            <v-img :src="items.src"></v-img>
                        </v-avatar>
                    </div>
                </v-card> -->
                <!-- fdsfs -->
                <div v-if="etap == 6">
                    <v-card v-for="(n, i) in listEvent" color="#952175" dark style="margin-top: 5px;" :key="i">
                        <div class="d-flex flex-no-wrap justify-space-between">
                            <div>
                                <v-card-title class="text-h5">{{ n.name }}</v-card-title>

                                <v-card-subtitle>Дата: День: {{ n.dts }} Месяц: {{ n.dtm }}</v-card-subtitle>

                                <v-card-actions>

                                    <v-btn class="ml-2 mt-5" outlined rounded small
                                        @click="OnRead(n.id, n.dts, n.dtm, n.name)">
                                        Изменить
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn class="ml-2 mt-5" outlined rounded small
                                        @click="OnDel(n.id)">
                                        Удалить
                                    </v-btn>
                                </v-card-actions>
                            </div>

                        </div>
                    </v-card>
                    <v-btn class="ml-2 mt-5" outlined rounded small @click="etap = 5">
                        Главное меню
                    </v-btn>
                </div>
                <!-- hdsk -->
                <v-card v-if="etap == 3">

                    <v-card-text>
                        О каком событии вам напомнить? Это может быть например: Чей то день рождения, профессиональный праздник или другие памятные даты важные для вас и ваших близких. Просто напишите название памятной даты и отправьте в чат. Саму дату я спрошу в следующем сообщении. 😄
                        <v-form ref="form">

                            <v-select
                                :items="['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']"
                                label="День события" solo v-model="dt.dts"></v-select>
                            <v-select
                                :items="['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']"
                                label="Месяц события" solo v-model="dt.dtm"></v-select>
                            <v-text-field v-model="dt.name" label="Название события"></v-text-field>
                        </v-form>

                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="#21a7ac" class="mr-4" @click="NextEtap" v-if="rd==false">
                            Добавить событие!
                        </v-btn>
                        <v-btn color="#21a7ac" class="mr-4" @click="etap=5" v-if="rd==false">
                            Назад
                        </v-btn>
                        <v-btn color="#21a7ac" class="mr-4" @click="NextEtap" v-else>
                            Сохранить
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="#21a7ac" class="mr-4" @click="etap=6" v-if="rd==true">
                            Назад
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
                <v-card v-if="etap == 4">

                    <v-card-text>
                        
Поздравляю! Вы добавили все события! Мы напомним о них вам за 10, за 4 и за 1 день и предложим подходящие букеты с вашей персональной скидкой.

События можно добавить через кнопку "Добавить событие" или отредактировать через кнопку «Список событий».

Получить свою подарочную розу вы можете у нас лично в магазине, либо мы можем приложить ее к вашему заказу. При заказе посто назовите нашему специалисту код ROZA4345.



                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="#21a7ac" class="mr-4" @click="etap = 5">
                            Добавить событие!
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
// @ is an alias to /src


export default {
    name: 'Home',
    data: () => ({
        etap: 0,
        dialog: false,
        dt: {
            id: 0,
            dts: '',
            dtm: '',
            name: ''
        },
        rd: false,
        items:
        {
            color: '#1F7087',
            src: 'https://cdn.vuetifyjs.com/images/cards/foster.jpg',
            title: 'Supermodel',
            artist: 'Foster the People',
        },


    }),

    components: {

    },
    computed: {
        User() {
            return this.$store.getters.getPerson;
        },
        listEvent() {
            return this.$store.getters.getEvent;
        }
    },
    methods: {
        NextEtap() {
            console.log(this.dt);
            if (this.etap == 0) {
                if (this.User.phone == '') {
                    this.$store.dispatch("setPhone").then(r=>{
                        if(r>0){
                            console.log(r);
                            this.etap = 5;
                        } else {
                            this.etap += 2;
                        }
                        
                    });
                    
                    

                } else {
                    this.etap = 5;
                }
            }
            else {
                if (this.etap == 3) {
                    console.log(this.dt);
                    this.$store.dispatch("saveEvent", this.dt);
                    if (this.listEvent.length == 3) {
                        if(this.rd == true){
                            this.etap = 6;
                            this.rd = false;
                        } else {

                            this.etap = 5;
                        }
                    } else {
                        this.etap = 5;
                    }
                    this.dt.id = 0;
                    this.dt.dtm = '';
                    this.dt.dts = '';
                    this.dt.name = '';
                } else {
                    this.etap = 3;
                }
            }

        },
        SaveData() {
            if (this.dt.old == '') {
                let t = {
                    'login': this.dt.login,
                    'passwd': this.dt.ps,
                }
                this.$store.dispatch("AuthPerson", t).then(rs => {
                    console.log(rs);
                    if (rs == true) {

                        this.$router.push('/')
                    } else {
                        alert('Не верный логин или пароль!');
                    }
                });
            } else {
                alert('Не верный логин или пароль!')
            }

        },
        OnRead(q, w, e, r) {
            this.dt.id = q;
            this.dt.dtm = e;
            this.dt.dts = w;
            this.dt.name = r;
            this.rd = true;
            this.etap = 3;
        },
        OnDel(v){
            this.$store.dispatch("delEvent", v)
        },

    },
    mounted() {

        if (this.isAuthUser) {
            this.$router.push('/')
        }
        this.$store.dispatch("getlistUnits");
    }
}
</script>